import React from "react";

function NonLayout({ children }) {
  return (
    <div className="flex h-screen w-screen">
      <div className="surface-hover w-full h-full p-3">{children}</div>
    </div>
  );
}

export default NonLayout;
