import React from 'react';
import SideBar from "./SideBar";
import Header from "./Header";


function Layout({ children }) {
    return (
        <div className='flex h-screen w-screen'>
            <div className=''>
                <SideBar />
            </div>
            <div className='flex flex-column w-full '>
                <div className='w-full'>
                    <Header />
                </div>
                <div className='surface-hover w-full h-full p-3'>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Layout;