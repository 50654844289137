
import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'primereact/menu';

export default function SideBar() {

    const itemRenderer = (item) => (
        <div className='p-menuitem-content'>
            <Link to={item.url} className='no-underline'>
                <span className={`flex align-items-center p-menuitem-link pl-5 ${item.url === window.location.pathname ? 'menuItemSelected' : ''}`}>
                    <span className={item.icon} />
                    <span className="mx-3">{item.label}</span>
                </span>
            </Link>
        </div>
    );

    let items = [
        {
            template: () => {
                return (
                    <span className="inline-flex align-items-center gap-1 px-2 py-2 mb-5">
                        <span className="font-medium text-xl font-semibold">
                            <span style={{ color: '#ff5400' }}>iTomo </span>
                            <span className="text-primary">Languages</span>
                        </span>
                    </span>
                );
            }
        },
        {
            label: 'Languages',
            items: [
                {
                    label: 'Ngôn ngữ',
                    icon: 'pi pi-language',
                    url: '/languages',
                    template: itemRenderer
                }
            ]
        }

    ];

    return (
        <div>
            <Menu model={items} className="h-screen border-noround overflow-y-auto hidden lg:flex w-14rem pl-3" />
        </div>
    )
}
